<template>
  <div :class="[logo ? 'mt-1 pt-px' : '']">
    <Popover class="relative">
      <PopoverButton>
        <MenuButton :title="customer.fullName">
          <Image v-if="logo" :src="logo" :width="40" :height="40" sizes="40px" layout="rounded-xl" class-name="rounded-xl hover:opacity-50" />
          <UserIcon v-else class="w-6 h-6" />
          <span class="sr-only">{{ $t('user-avatar') }}</span>
          <span v-if="count" class="bg-button-accent py-0.5 px-1 text-xs leading-none font-bold text-primary-dark rounded-full absolute top-0 end-0 mt-0 -me-1">
            {{ count }}
          </span>
        </MenuButton>
      </PopoverButton>
      <Transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel v-slot="{ close }" class="absolute right-0 mt-2 py-4 w-44 bg-light rounded shadow-700 z-20">
          <Scrollbar class-name="w-full h-full" :options="{ scrollbars: { autoHide: 'never' } }">
            <ul>
              <li v-for="({routeCode, label, role, badge}) in siteSettings.authorizedLinks" :key="`${routeCode}_${label}`" :class="{ 'hidden': role === 'supplier' && !auth.idSupplier }">
                <button
                  class="block w-full py-2 px-6 text-sm text-start font-semibold text-bolder transition duration-200 hover:text-accent-hover hover:underline focus:outline-none"
                  @click="() => handleClick(routeCode, close)"
                >
                  {{ $t(label) }}
                  <span v-if="badge && auth?.notificationInfo && auth?.notificationInfo[badge]" class="bg-accent-dark px-1 text-xs leading-none font-bold text-bolder rounded-full">
                    {{ auth?.notificationInfo[badge] }}
                  </span>
                </button>
              </li>
            </ul>
          </Scrollbar>
        </PopoverPanel>
      </Transition>
    </Popover>
  </div>
</template>

<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { siteSettings } from "@settings/site.settings";
import { useAuthStore } from '@stores/auth';
import { useCustomerStore } from '@stores/customer';
import Scrollbar from "@components/ui/scrollbar";
import MenuButton from "@components/ui/menu-button";
import UserIcon from "@components/icons/user-icon";
import Image from "@components/ui/image";

const { $eventBus } = useNuxtApp();
const auth = useAuthStore();
const customer = useCustomerStore();
const logo = ref(customer.logo);

const count = computed(() => auth?.notificationInfo?.unreadSalesMessagesCount + auth?.notificationInfo?.unreadPurchasesMessagesCount);

const handleClick = async (routeCode, close) => {
    await navigatorTo(translatePath(routeCode));
    close();
}

const supplierUpdate = (value) => {
  if (customer?.supplier?.id === value?.id) {
    logo.value = '';
    nextTick(() => logo.value = value?.logo || null);
  }
};

watch(() => customer.logo, (value) => {
  logo.value = '';
  nextTick(() => logo.value = value || null);
});

onMounted(async () => {
  $eventBus.on('supplier:update', supplierUpdate);
});

onUnmounted(() => {
  $eventBus.off('supplier:update', supplierUpdate);
});

</script>
